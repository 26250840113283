import React from "react";
import { graphql } from "gatsby";
import { getPostsFromGraphQL } from "../utils";
import {ChartsList, Layout, HeadlinePanel, MetaTags}  from '../components'
import withLocation from "../components/Common/withLocation";

const List = ({pageContext, data, search}) => {
  const {sortBy, sortOrder} = search;
  const sort={sortBy, sortOrder};
  const {showVariations} = pageContext;
  const posts = getPostsFromGraphQL(data, showVariations, sort);
  return (
    <Layout type="full">
      <MetaTags
        title={`Charts Definitions - Graphopedia`}
      />
      <HeadlinePanel includeVariations={showVariations} />
      <ChartsList posts={posts} isExpanded={showVariations} />
    </Layout>
  );
};

export default withLocation(List);

export const postsQuery2 = graphql`
  query PostsQuery2 {
    ...postList
  }
`;
